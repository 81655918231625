import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";

import styles from "assets/jss/material-kit-pro-react/views/TermsPage";

// Sections for this page

// import image from "assets/img/dumpster2-bg.jpg";

const useStyles = makeStyles(styles);

export default function TermsPage(props) {
  const classes = useStyles();
  const pageClasses = classNames({
    [classes.container]: true,
  });
  const { ...rest } = props;
  return (
    <div>
      <Header
        color="white"
        brand="TelaVoucher"
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        {...rest}
      />
      <div className={pageClasses}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <h2 align={"center"} className={classes.title}>
              Dumpster Rental Terms of Service and Disclaimer
            </h2>
            <h4 align={"center"} className={classes.title}>
              By doing business with us, you are consenting to the following
              Terms and Conditions:
            </h4>
            <p className={classes.pcontent}>
              THIS SERVICE AGREEMENT (hereinafter referred to as “Agreement”) is
              made official immediately upon the ordering (either verbally or
              online) of a service to be provided by TelaVoucher LLC (Company)
              by for and between COMPANY and Carrier, TPA, MGA, IA firm
              authorized to transact business on behalf of the carrier, the
              ultimate client, (CLIENT) specializing in providing a voucher for
              a dumpster rental, and should that voucher be redeemed with
              TelaVoucher Dumpster, delivering the agreed upon container to the
              location agreed upon in a timely fashion. TelaVoucher with its
              principal place of business in Miami, Florida doing business as
              TelaVoucher LLC (hereinafter referred to as “COMPANY”) and YOU
              (hereinafter referred to as “CLIENT”). Upon execution of this
              Agreement by CLIENT, COMPANY will provide services for the CLIENT
              under the Terms and Conditions set forth below in this Agreement:
            </p>
            <ol>
              <li className={classes.pcontent}>
                SERVICES: COMPANY shall comply with all local, state and federal
                laws in connection with all services rendered by the Company.
                COMPANY will utilize skills and reasonable business practices,
                within the trade, in conjunction with the necessary procedures
                in order to perform all services in compliance with the law.
                Services shall commence upon receipt of the executed copy of
                this Agreement to COMPANY. COMPANY may subcontract, sub-let or
                assign such portion of the services under this Agreement as it
                may believe advisable. Any contractor or subcontractor utilized
                by COMPANY shall be herein referred to as “CONTRACTOR”. COMPANY
                reserves the right, at any time, to stop performance of the
                services hereunder until such time as any past due payment,
                according to the payment terms of this Agreement, are brought up
                to date.
              </li>
              <ol className={classes.olstuff}>
                <li className={classes.pcontent}>
                  1.1 SERVICES & LIABILITIES: COMPANY will provide CLIENT with
                  the information necessary for CLIENT to render construction
                  services to third parties (“Consumers”) who solicit COMPANY
                  for such services. However, COMPANY will not render any
                  construction services to Consumers. Providing construction
                  services to Consumers is the sole responsibility of CLIENT. In
                  addition to other provisions in this agreement, COMPANY is not
                  liable for any damages or liabilities incurred by Consumers or
                  CLIENT resulting from the services rendered by CLIENT to
                  Consumers.
                  <p className={classes.pcontent}>
                    If COMPANY incurs any liabilities resulting from or related
                    to services rendered by CLIENT to Consumers, then CLIENT
                    will fully indemnify and hold the COMPANY harmless for such
                    damages.
                  </p>
                </li>
              </ol>
              <li className={classes.pcontent}>
                DUTIES IMPOSED ON CLIENT: In addition to the above services
                rendered by COMPANY, during the term of this Agreement, the
                CLIENT shall provide COMPANY with any and all information or
                documents which may be necessary for COMPANY to fulfill its
                obligations under the terms herein. Moreover, CLIENT agrees to
                cooperate with COMPANY in any manner necessary, so long as it is
                in compliance with the laws, in order to allow COMPANY to
                fulfill its obligations under the term herein.
              </li>
              <li className={classes.pcontent}>
                COMPENSATION AND EXPENSES: In consideration for the services to
                be performed by COMPANY under this Agreement, the CLIENT will
                pay compensation to COMPANY in the total agreed upon amount.
                Additionally, CLIENT will be held responsible for any additional
                charges or overages that are incurred as a result of their
                actions. If additional charges occur, CLIENT will be billed
                separately. Examples of additional fees are further described on
                the Payment Schedule on Exhibit A, attached herein and
                incorporated by reference.
                <br />
                Additionally, a list of restricted items and restricted actions
                that may warrant additional fees or refusal to complete services
                are described in EXHIBIT B and EXHIBIT C.
              </li>
              <li className={classes.pcontent}>
                LATE PAYMENT: CLIENT will pay COMPANY all compensation
                immediately upon execution of this Agreement or on such date(s)
                as agreed to by the Parties. If CLIENT and COMPANY agree to a
                payment schedule, then it must be in writing and attached to
                this Agreement and Incorporated by reference herein as “Exhibit
                A.” Failure of CLIENT to pay any fees after the due date is a
                material breach of this Agreement, justifying suspension of the
                COMPANY’S performance and it shall be sufficient cause for
                immediate termination of this Agreement by COMPANY. Any such
                suspension will in no way relieve CLIENT from payment of
                compensation. CLIENT shall be liable for any costs associated
                with collection, including, but not limited to, legal costs,
                attorneys’ fees, courts costs, and collection agency fees.
              </li>
              <li className={classes.pcontent}>
                INDEPENDENT CONTRACTOR: Nothing herein shall be construed to
                create an employer-employee relationship between CLIENT and
                COMPANY. COMPANY is an independent contractor and not an
                employee of CLIENT or any of its subsidiaries or affiliates. It
                is understood that CLIENT shall not withhold any amounts for
                payment of taxes from the compensation of COMPANY. COMPANY shall
                not represent to be or hold itself out as an employee of CLIENT.
              </li>
              <li className={classes.pcontent}>
                TERM: This Agreement shall commence immediately upon the
                reservation of services by CLIENT from COMPANY and shall
                continue after the date of this Agreement unless terminated by
                in writing with notice to the COMPNAY and include all parties to
                this Agreement. COMPANY has the right to terminate this
                Agreement in its sole discretion if CLIENT violates or proposes
                to violate any applicable federal or state law, rule or
                regulation. COMPANY may also terminate this Agreement in its
                sole discretion.
              </li>
              <li className={classes.pcontent}>
                ACCEPTANCE: The CLIENT acknowledges that they have reviewed this
                Agreement and acknowledges that he, she or it has read and
                understands the content thereof and accepts the Terms and
                Conditions stated herein this Agreement. The services specified
                are hereby authorized and payment will be made as outlined
                herein.
                <br />
                <br />
                By doing business with COMPANY, the CLIENT hereby agrees to all
                Terms and Conditions set forth in this agreement.
              </li>
              <h4 align={"center"} className={classes.title}>
                TERMS & CONDITIONS
              </h4>
              <li className={classes.pcontent}>
                LIMITATION OF LIABILITY. It is understood and agreed that
                COMPANY HEREBY DISCLAIMS ANY AND ALL LIABILITY AND CLIENT SHALL
                ASSUME ALL RESPONSIBILITY FOR OBTAINING INSURANCE TO COVER
                LOSSES OF ALL TYPES. COMPANY MAKES NO WARRANTIES, EXPRESS OR
                IMPLIED, EXCEPT AS SPECIFICALLY STATED HEREIN. SUCH WARRANTIES
                ARE IN LIEU OF ALL OTHER WARRANTIES, WRITTEN OR ORAL, STATUTORY,
                EXPRESS OR IMPLIED. COMPANY SHALL IN NO EVENT BE LIABLE FOR ANY
                INCIDENTAL, SPECIAL, OR CONSEQUENTIAL DAMAGES OF ANY NATURE,
                EVEN IF COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
                DAMAGES. CLIENT UNDERSTANDS THAT COMPANY IS ACTING AS A BROKER
                OF SERVICES BETWEEN AN INDEPENDENT CONTRACTOR AND CLIENT.
                COMPANY MAKES NO GUARANTEE AS TO THE QUALITY OF SERVICES
                RENDERED BY THE CONTRACTOR. ANY DAMAGES OR INSTANCES OF
                NEGLIGENCE CAUSED BY THE CONTRACTOR ARE THE SOLE RESPONSIBILITY
                OF THE CONTRACTOR, AND NOT COMPANY. IN NO EVENT SHALL COMPANY’S
                TOTAL LIABILITY TO CLIENT FOR ALL DAMAGES, LOSSES, AND CAUSES OF
                ACTION (WHETHER IN CONTRACT, TORT, OR OTHERWISE) EXCEED THE
                AMOUNT PAID BY CLIENT, IF ANY, FOR THE VALUE OF THE SERVICES
                PROVIDED HEREUNDER.
              </li>
              <li className={classes.pcontent}>
                INDEMNIFICATION. CLIENT shall indemnify and hold harmless
                COMPANY against all claims, obligations or liabilities including
                court costs and attorneys’ fees, arising out of CLIENT’s
                tortious or unauthorized acts, misrepresentations, omissions,
                failure to perform its obligations hereunder, or any acts not
                expressly authorized in writing, related to or beyond the scope
                of this Agreement.
              </li>
              <li className={classes.pcontent}>
                NON-CIRCUMVENTION. CLIENT agrees that the terms and conditions
                of this Agreement are fully applicable and binding to itself,
                its officers, directors, shareholders, affiliates, subsidiaries,
                CLIENTs, representatives, employees, associates, assigns,
                trustees, heirs and/or assigns or executors, and CLIENT binds
                itself not to deal independently with any person, business,
                corporations, partnership, buyer, sellers, borrowers, lenders,
                agents, brokers, institutions, including their affiliates,
                subsidiaries, contractee’s, CLIENTs, representatives, employees,
                associates, assigns, trustees, heirs and/or assigns, or
                executors or other entities introduced or known to CLIENT,
                without COMPANY’S knowledge, subject to all of the terms and
                conditions in this Agreement.
                <br />
                <br />
                CLIENT agrees not to attempt to circumvent, avoid or bypass
                COMPANY in any manner, regarding any agreements or disclosures
                made by the Parties.
              </li>
              <li className={classes.pcontent}>
                COVENANT NOT TO COMPETE. The CLIENT covenants that for a period
                of one year from the date of execution of this Agreement except
                as approved in writing by COMPANY, either directly or
                indirectly, with or without compensation, for itself, or
                through, on behalf of, or in conjunction with any person,
                persons, partnership, corporation or other entity, CLIENT shall
                not employ or engage, or seek to employ or engage, any person
                who is at that time employed or engaged by COMPANY or directly
                or indirectly attempt to induce such person to leave its
                employment or engagement.
              </li>
              <li className={classes.pcontent}>
                TERMINATION. This Agreement may be terminated by COMPANY and
                COMPANY may, at its option, declare any unpaid balance and other
                sums payable by CLIENT hereunder immediately due and payable for
                any one or more of the following reasons: (a) if CLIENT fails to
                make payment when due; or (b) the CLIENT substantially breaches
                any other obligation of this Agreement.
              </li>
              <li className={classes.pcontent}>
                CANCELLATION. CLIENT may cancel services to be rendered by
                COMPANY without any fee by communicating such termination in
                writing at least 48 hours prior to such time when services of
                COMPANY have to be rendered. CLIENT expressly agrees that
                COMPANY shall be authorized to retain the sum of entire
                compensation as cancellation fee or a lesser amount at COMPANY’S
                sole discretion if services are cancelled with less than 48
                hours written notice to COMPANY. CLIENT hereby and expressly
                agrees that upon cancellation of future services, all services
                rendered to date are due in full and payable with no delay.
              </li>
              <li className={classes.pcontent}>
                MEDIATION AND ARBITRATION. Any disputes between the Parties,
                whether arising under this agreement or otherwise, which the
                parties cannot resolve between themselves using good faith shall
                be referred to a court certified mediator of the Circuit Court
                in the County of the principal office of COMPANY, and any
                mediation shall be held in the County of the principal office of
                COMPANY. The CLIENT shall bear the cost of said mediation. In
                the event that said dispute is not resolved in mediation, the
                parties shall submit the dispute to a neutral arbitrator
                residing in the County of the principal address of COMPANY. The
                arbitration shall be held in the County of the principal office
                of COMPANY and the CLIENT shall bear the cost of said
                arbitration.
                <br />
                <br />
                In the event that the parties are unable to agree upon an
                arbitrator within 15 days of the date on which either party
                requests arbitration of a matter, the arbitrator shall be
                provided by the American Arbitration Association. The parties
                further agree that full discovery shall be allowed to each party
                to the arbitration and a written award shall be entered
                forthwith. Any and all types of relief that would otherwise be
                available in Court shall be available to both parties in the
                arbitration. The decision of the arbitrator shall be final and
                binding. Arbitration shall be the exclusive legal remedy of the
                parties. If either party refuses to comply with a ruling or
                decision of the arbitrator, the party not complying with the
                ruling or decision of the arbitrator shall pay all the court
                costs and reasonable attorney’s fees (including Trial and
                Appellate attorney’s fees) incurred in enforcing the ruling or
                decision of the arbitrator. Any rights of injunctive relief
                shall be in addition to and not in derogation or limitation of
                any other legal rights.
              </li>
              <li className={classes.pcontent}>
                ENTIRE AGREEMENT. This Agreement constitutes the entire
                understanding of the parties and supersedes all prior
                discussions, negotiations, agreements and understandings,
                whether oral or written, with respect to its subject matter.
              </li>
              <li className={classes.pcontent}>
                MODIFICATION. No change or modification of this Agreement shall
                be valid unless it is IN WRITING AND SIGNED BY ALL THE PARTIES
                who are bound by the terms of this Agreement.
              </li>
              <li className={classes.pcontent}>
                SEVERABILITY. If any provision of this Agreement is held
                invalid, unenforceable, or void by a court of competent
                jurisdiction, this Agreement shall be considered divisible as to
                such provision, and the remainder of the Agreement shall be
                valid and binding.
              </li>
              <li className={classes.pcontent}>
                VENUE AND JURISDICTION. Should a lawsuit be necessary to enforce
                this Agreement the parties agree that jurisdiction and venue are
                waived and suit shall be brought in the county of the principal
                office of COMPANY.
              </li>
              <li className={classes.pcontent}>
                NO-WAIVERS. Waiver by any party of any other party’s breach of
                any provision of this Agreement shall not operate nor be
                construed as a waiver of any subsequent breach, and the waiver
                by any party to exercise any right or remedy shall not operate
                nor be construed as a waiver or bar to the exercise of such
                right or remedy upon the occurrence of any subsequent breach. No
                action of either party to this Agreement shall be interpreted as
                waiver unless waiver is in writing.
              </li>
              <li className={classes.pcontent}>
                HEADINGS. Headings in this Agreement are for convenience only
                and shall not be used to interpret or construe its provisions.
              </li>
              <li className={classes.pcontent}>
                GOVERNING LAW. This Agreement shall be governed by the laws of
                the State of New Jersey (without regard to the laws that might
                be applicable under principles of conflicts of law) as to all
                matters, including, but not limited to, matters of validity,
                construction, effect and performance.
              </li>
              <li className={classes.pcontent}>
                COUNTERPARTS. This Agreement may be executed in two or more
                parts, each of which shall be deemed an original but all of
                which together shall be one and the same instrument.
              </li>
              <li className={classes.pcontent}>
                FACSIMILE COPY, EMAIL OR ELECTRONIC SIGNATURE. A facsimile or
                email copy of this Agreement and any signatures affixed hereto
                (either manual or electronic) shall be considered for all
                purposes as originals.
              </li>
            </ol>
            <h4 align={"center"} className={classes.title}>
              GENERAL DISCLAIMER
            </h4>
            <ol className={classes.olstuff}>
              <p className={classes.pcontent}>
                TelaVoucher LLC is provided as is without any representations or
                warranties, expressed or implied. Our parent company, holding
                companies, and our services do not make any representations or
                warranties in relation to this or the information and materials
                provided on this service. We make every attempt possible, in a
                timely and reasonable manner to make sure all data on this
                service is accurate, true, up-to-date and of the highest
                quality. However, we provide local roll off and weekly trash
                services nationally and every region we service is different and
                our service areas, availability, sizes and prices change
                frequently and those changes are not always immediately
                reflected. There is the possibility that it will take a week or
                two to have our web developers make any necessary updates.
                Without prejudice to the generality of the foregoing paragraph,
                our parent company and holding company does not warrant that:
                this service will be constantly available, or available at all;
                or the information on this service is always 100% up to date due
                to the reasons mentioned above. Nothing constitutes, or is meant
                to constitute, advice of any kind. [If you require advice in
                relation to any legal, financial or medical matter you should
                consult an appropriate professional.]
              </p>
              <p className={classes.pcontent}>
                <strong>Limitations of Liability: </strong>
                TelaVoucher LLC and our parent company will not be liable to you
                (whether under the law of contact, the law of torts or
                otherwise) in relation to the contents of, or use of, or
                otherwise in connection with, this service: <br /> <br />
                for any indirect, special or consequential loss; or <br />{" "}
                <br />
                for any business losses, loss of revenue, income, profits or
                anticipated savings, loss of contracts or business
                relationships, loss of reputation or goodwill, or loss or
                corruption of information or data. <br /> <br />
                These limitations of liability apply even if our company has
                been expressly advised of the potential loss.
              </p>
              <p className={classes.pcontent}>
                <strong>Exceptions: </strong>
                Nothing in this disclaimer will exclude or limit any warranty
                implied by law that it would be unlawful to exclude or limit;
                and nothing in this disclaimer will exclude or limit our
                company’s liability in respect of any: <br /> <br />
                death or personal injury caused by TelaVoucher LLC or holding
                companies’ negligence;
                <br /> <br />
                fraud or fraudulent misrepresentation on the part of our company
                <br /> <br />
                matter which it would be illegal or unlawful for our company to
                exclude or limit, or to attempt or purport to exclude or limit,
                its liability.
              </p>
              <p className={classes.pcontent}>
                <strong>Reasonableness: </strong>
                By using this service, you agree that the exclusions and
                limitations of liability set out in this disclaimer are
                reasonable and unless notified in writing, the terms are
                applicable on all carriers, customers, consumers, contractors or
                any other party that uses either the voucher or the dumpster
                drop off and pick up service. <br /> <br />
                If you do not think they are reasonable, you must not use this
                service.
              </p>
              <p className={classes.pcontent}>
                <strong>Other parties: </strong>
                You accept that, as a limited liability corporation, TelaVoucher
                LLC and has an interest in limiting the personal liability of
                its officers and employees. You agree that you will not bring
                any claim personally against TelaVoucher LLC and or its officers
                or employees. <br /> <br />
                Without prejudice to the foregoing paragraph, you agree that the
                limitations of warranties and liability set out in this service
                disclaimer will TelaVoucher LLC, its subsidiaries, and its
                officers, employees, agents, subsidiaries, successors, assigns
                and sub-contractors. <br /> <br />
                Unenforceable provisions <br /> <br />
                If any provision of this disclaimer is, or is found to be,
                unenforceable under applicable law, that will not affect the
                enforceability of the other provisions of this disclaimer.
              </p>
            </ol>
          </GridItem>
        </GridContainer>
      </div>
      <Footer
        content={
          <div className={classes.center}>
            &copy; {1900 + new Date().getYear()} TelaVouchers powered by
            <a
              href="https://www.telaclaims.com"
              className="aClasses"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              Telaclaims
            </a>
          </div>
        }
      />
    </div>
  );
}
