/* eslint-disable react/jsx-key */
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

// core components
import { Typography } from "@material-ui/core";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CardHeader from "components/Card/CardHeader.js";

import styles from "../../../assets/jss/material-kit-pro-react/components/redeemForm";
import { voucherTypes } from "api/merge";

const useStyles = makeStyles(styles);

const dumpsterSpecifics = (field, id, value) => {
  if (id === voucherTypes.DUMPSTER) {
    if (field === "scheduledDate") {
      const requestedDate = new Date(value);
      return requestedDate.toLocaleDateString("en-US");
    }
  }
  return value;
};

const buildOutput = (schema, id, info) => {
  const response = [];
  const keys = Object.keys(schema);
  for (let i = 0; i < keys.length; i++) {
    if (typeof info[keys[i]] !== "undefined") {
      const value = dumpsterSpecifics(keys[i], id, info[keys[i]]);
      response.push({
        id: `${keys[i]}-${i}`,
        title: schema[keys[i]].title,
        value,
      });
    }
  }
  return response;
};

export default function ConfirmationForm(props) {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  setTimeout(function () {
    setCardAnimation("");
  }, 400);
  const classes = useStyles();
  const data = buildOutput(
    props.schemata.redemption_attributes.schema.definitions,
    props.schemata.id,
    props.requestInfo
  );
  return (
    <div>
      <GridItem xs={12} sm={12} md={12}>
        <Card className={classes[cardAnimaton]}>
          <form className={classes.form}>
            <CardHeader color="info" className={classes.cardHeader}>
              <h3 align={"left"} className={classes.title}>
                Requested Service Details
              </h3>
            </CardHeader>
            <CardBody>
              {data.map((element) => (
                <p key={element.id}>
                  <strong>{`${element.title}`}</strong>
                  {`: ${element.value}`}
                </p>
              ))}
            </CardBody>
            <CardFooter className={classes.cardFooter}>
              <Button
                simple
                color="primary"
                size="lg"
                onClick={props.onClickCancelHandler}
              >
                <Typography variant="button" color="primary">
                  Done
                </Typography>
              </Button>
            </CardFooter>
          </form>
        </Card>
      </GridItem>
    </div>
  );
}

ConfirmationForm.propTypes = {
  requestInfo: PropTypes.object,
  onClickCancelHandler: PropTypes.func,
  voucherTypes: PropTypes.object,
  schemata: PropTypes.object,
};
