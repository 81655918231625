import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
// import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-kit-pro-react/components/termsStyle.js";

const useStyles = makeStyles(styles);

export default function TermsArea(props) {
  const classes = useStyles();
  const { title } = props;
  return (
    <div className={classes.termsArea}>
      <div className={classes.descriptionWrapper}>
        <h3 className={classes.title}>{title}</h3>
      </div>
    </div>
  );
}

TermsArea.propTypes = {
  title: PropTypes.string.isRequired,
};
