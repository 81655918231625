import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

import styles from "assets/jss/material-kit-pro-react/views/landingPageStyle.js";

// Sections for this page
import ProductSection from "./Sections/ProductSection.js";
import ServiceAreaSection from "./Sections/ServiceAreaSection.js";
import TermsSection from "./Sections/TermsSection.js";

// const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function LandingPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <Header
        /* RRG - changed transparent to white */
        color="white"
        // routes={dashboardRoutes}
        brand="TelaVoucher"
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        {...rest}
      />
      <Parallax
        filter={"dark"}
        image={require("assets/img/dumpster-roof-01.png")}
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <h1 className={classes.title}>
                Redeem a prepaid Voucher for your Home.
              </h1>
              <h4>
                We are happy to arrange service for your location. Click below
                and we will get to work for you.
              </h4>
              <br />
              <Button
                color="success"
                size="lg"
                href="/redeem"
                target="_parent"
                rel="noopener noreferrer"
              >
                REDEEM YOUR VOUCHER
              </Button>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classes.main}>
        <div className={classes.container}>
          <ProductSection />
          <ServiceAreaSection />
        </div>
      </div>
      <div className={classes.mainAlt}>
        <TermsSection />
      </div>
      <Footer
        content={
          <div className={classes.center}>
            &copy; {1900 + new Date().getYear()} TelaVouchers powered by
            <a
              href="https://www.telaclaims.com"
              className="aClasses"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              Telaclaims
            </a>
          </div>
        }
      />
    </div>
  );
}
