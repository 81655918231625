import React from "react";
import FormattedNumberField from "../FormattedNumberField";
import { withJsonFormsControlProps } from "@jsonforms/react";
import { makeStyles, TextField, Box } from "@material-ui/core";
import merge from "lodash/merge";
import { computeLabel } from "@jsonforms/core";

const withStyles = makeStyles(theme => ({
  phoneField: {
    margin: theme.spacing(0, 0, 2, 0)
  }
}));

function PhoneInput(props) {
  const {
    description,
    data,
    handleChange,
    path,
    required,
    config,
    uischema,
    label,
    errors
  } = props;

  const appliedUiSchemaOptions = merge({}, config, uischema.options);
  const isValid = errors.length === 0;

  // console.log("other props", others);
  const classes = withStyles();
  const format = null;
  return (
    <Box className={classes.phoneField}>
      <FormattedNumberField
        fullWidth
        // margin={'dense'}
        label={computeLabel(
          label,
          required,
          appliedUiSchemaOptions.hideRequiredAsterisk
        )}
        value={data || null}
        onValueChange={values => handleChange(path, values.value)}
        format={format || "(###) ###-####"}
        mask={" "}
        allowEmptyFormatting
        customInput={TextField}
        autoComplete={"phone"}
        showErrors={!isValid}
        messages={!isValid ? errors : description ? description : " "}
      />
    </Box>
  );
}

export default withJsonFormsControlProps(PhoneInput);
