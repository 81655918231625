import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "../../assets/jss/material-kit-pro-react/components/redeemForm";

import image from "assets/img/dumpster2-bg.jpg";

const useStyles = makeStyles(styles);

export default function NotFoundPage() {
  const classes = useStyles();
  return (
    <div>
      <Header
        absolute
        color="white"
        brand="Material Kit React"
        rightLinks={<HeaderLinks />}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center"
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={6}>
              <h1 className={classes.title}>Opps... Try Again</h1>
              <h4>
                Looks like you are trying to go somewhere that does not exist.
              </h4>
            </GridItem>
          </GridContainer>
        </div>
        <Footer whiteFont />
      </div>
    </div>
  );
}
