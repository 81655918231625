import Ajv from "ajv";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardFooter from "components/Card/CardFooter.js";
import GridItem from "components/Grid/GridItem";
import PhoneInput, { phoneInputTester } from "./PhoneInput";
import DatePicker, { datePickerTester } from "./DatePicker";
import React, { useState, useEffect } from "react";
import { Box, makeStyles, Typography, Button } from "@material-ui/core";
import { JsonForms } from "@jsonforms/react";
import { materialRenderers } from "@jsonforms/material-renderers";
import styles from "assets/jss/material-kit-pro-react/components/redeemForm";
import PropTypes from "prop-types";
import AjvErrors from "ajv-errors";

const deepCopy = (o) => JSON.parse(JSON.stringify(o));

const useStyles = makeStyles(styles);

const ajv = new Ajv({
  schemaId: "auto",
  allErrors: true,
  jsonPointers: true,
  errorDataPath: "property",
  verbose: true,
  // ...options
});
AjvErrors(ajv);

ajv.addFormat("custom-phone", "^[0-9]{10,20}$");
ajv.addFormat("custom-date", (testDate) => {
  const current = new Date().getTime();
  const comparison = new Date(testDate).getTime();
  return comparison > current;
});

function renderers(voucherType, voucherTypes) {
  if (voucherType === voucherTypes.ROOF) {
    return [
      ...materialRenderers,
      { tester: datePickerTester, renderer: DatePicker },
      { tester: phoneInputTester, renderer: PhoneInput },
    ];
  }
  if (voucherType === voucherTypes.DUMPSTER) {
    return [
      ...materialRenderers,
      { tester: datePickerTester, renderer: DatePicker },
      { tester: phoneInputTester, renderer: PhoneInput },
    ];
  }
}

function ScheduleForm(props) {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  setTimeout(function () {
    setCardAnimation("");
  }, 700);
  const classes = useStyles();
  const [schemata, setSchemata] = useState({});
  useEffect(() => {
    setSchemata(deepCopy(props.schemata.redemption_attributes));
  }, [props.schemata.redemption_attributes]);
  const { schema, uiSchema, initialData } = schemata;
  const [data, setData] = useState({});
  if (props.voucher.type.id === props.voucherTypes.ROOF) {
    // add defaults
    if (typeof data !== "undefined") {
      data["fullName"] = props.voucher.claim_info.insured_name;
    }
    if (typeof data !== "undefined") {
      data["insuredEmail"] = props.voucher.claim_info.insured_email;
    }
    // add subcontractors
    if (typeof schema !== "undefined") {
      if (schema.definitions.selectedContractor.enum.length === 0) {
        schema.definitions.selectedContractor.enum =
          props.voucher.type.redemption_attributes.schema.definitions.selectedContractor.enum;
      }
    }
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    props.onSubmit(data);
  };
  const handleClearForm = () => {
    setData(initialData);
    props.onClickClearHandler();
  };
  const render = renderers(props.schemata.id, props.voucherTypes);

  return (
    <Box className={classes.box}>
      <GridItem xs={12} sm={12} md={12}>
        <Card className={classes[cardAnimaton]}>
          <form className={classes.form}>
            <CardHeader color="info" className={classes.cardHeader}>
              <h3 align={"left"} className={classes.title}>
                Schedule Service
              </h3>
            </CardHeader>
            <CardBody>
              <JsonForms
                schema={schema}
                uischema={uiSchema}
                data={data}
                onChange={(state) => setData(state.data)}
                renderers={render}
                ajv={ajv}
              />
              <CardFooter className={classes.cardFooter}>
                <Button
                  className={classes.button}
                  onClick={props.onClickCancelHandler}
                  color="default"
                >
                  <Typography variant="button">Cancel</Typography>
                </Button>
                <Button
                  className={classes.button}
                  onClick={handleClearForm}
                  color="default"
                >
                  <Typography variant="button">Clear</Typography>
                </Button>
                <Button
                  className={classes.button}
                  onClick={handleSubmit}
                  variant="outlined"
                  color="primary"
                >
                  <Typography variant="button">Submit</Typography>
                </Button>
              </CardFooter>
            </CardBody>
          </form>
        </Card>
      </GridItem>
      {/* <div>
        {JSON.stringify(data, null, 2)
          .split("\n")
          .map((l, i) => (
            <div key={i}>{l}</div>
          ))}
      </div> */}
    </Box>
  );
}
ScheduleForm.propTypes = {
  schemata: PropTypes.object,
  onClickCancelHandler: PropTypes.func,
  onClickClearHandler: PropTypes.func,
  onSubmit: PropTypes.func,
  voucher: PropTypes.object,
  voucherTypes: PropTypes.object,
};

export default ScheduleForm;
