import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Check from "@material-ui/icons/Check";
import Warning from "@material-ui/icons/Warning";

import PropTypes from "prop-types";

// core components
import SnackbarContent from "components/Snackbar/SnackbarContent.js";

import styles from "../../../assets/jss/material-kit-pro-react/views/componentsSections/notificationsStyles.js";

const useStyles = makeStyles(styles);

const NotificationCaptions = (which) => {
  const captions = {
    getVoucher: {
      title: "GETTING YOUR VOUCHER",
      caption: "Please wait while we retrieve your voucher information.",
    },
  };
  return captions[which];
};

export default function SectionNotifications(props) {
  const classes = useStyles();
  let color, icon;
  switch (props.type) {
    case "success":
      color = "success";
      icon = Check;
      break;
    case "warning":
      color = "warning";
      icon = Warning;
      break;
    case "error":
      color = "danger";
      icon = "info_outline";
      break;
    default:
      break;
  }
  let wording = NotificationCaptions(props.which);
  let notification = (
    <SnackbarContent
      message={
        props.adhoc === "" ? (
          <span>
            <b>{wording.title}</b>
            {wording.caption}
          </span>
        ) : (
          <span>{props.adhoc}</span>
        )
      }
      color={color}
      icon={icon}
    />
  );

  return (
    <div>
      {props.display === true ? (
        <div className={classes.section} id="notifications">
          {notification}
        </div>
      ) : (
        <span></span>
      )}
    </div>
  );
}
SectionNotifications.propTypes = {
  type: PropTypes.string,
  which: PropTypes.string,
  adhoc: PropTypes.string,
  display: PropTypes.bool,
};
