import axios from "axios";

export const voucherTypes = Object.freeze({
  DUMPSTER: 1,
  ROOF: 3,
});

const APP_SECRET_KEY = "authkey";
const APP_SECRET_VALUE = "H83DF15479A73BCC7A19563BD58A7";

const mergeApiUrl = [
  "https://api-dev.telaclaims.cloud",
  "https://api-qa.telaclaims.cloud",
  "https://api.telaclaims.cloud",
];

const vendorDataFormat = (vendors) => {
  const response = [];
  vendors.results.forEach((vendor) => {
    const name = vendor.name || "";
    // bail if no name is available
    if (name === "") {
      return;
    }
    response.push(name);
  });
  return response;
};

const getVendors = async (lossLocation, e = 3) => {
  const specialty = "roofer";
  // const lossLocation = voucher.claim_info.loss_location.unformatted_address
  const request = {
    method: "get",
    url: `${
      mergeApiUrl[e - 1]
    }/organization/vendors/specialty?limit=10&term=${specialty}${
      typeof lossLocation !== "undefined" ? "" : ""
    } `, // `&loc=${lossLocation}` : ''}`,
    headers: {
      Authorization: `${APP_SECRET_KEY} ${APP_SECRET_VALUE}`,
    },
  };
  const response = await axios(request);
  return vendorDataFormat(
    typeof response.data.data !== "undefined" ? response.data.data : []
  );
};

const getInjectableData = async (data, e) => {
  if (data.type.id === voucherTypes.ROOF) {
    if (
      typeof data.type.redemption_attributes.schema.definitions
        .selectedContractor !== "undefined"
    ) {
      data.type.redemption_attributes.schema.definitions.selectedContractor[
        "enum"
      ] = await getVendors(undefined, e);
    }
  }
  return data;
};

export const get = async (id, e = 3) => {
  const response = {
    isError: false,
    data: null,
  };
  try {
    const url = `${mergeApiUrl[e - 1]}/claims/vouchers/for-redemption/${id}`;
    const reply = await axios.get(url);
    response.data = await getInjectableData(reply.data.data, e);
  } catch (err) {
    console.dir(err);
    response.isError = true;
    response.data = err.message;
    if (typeof err.response.data.errors !== "undefined") {
      if (err.response.data.errors.length > 0) {
        response.data = err.response.data.errors[0].message;
      }
    }
  }
  return response;
};

export const redeem = async (id, data, e = 3) => {
  const response = {
    isError: false,
    data: null,
  };
  const payload = {
    redemption_info: { ...data },
  };
  try {
    const url = `${mergeApiUrl[e - 1]}/claims/vouchers/redeem/${id}`;
    const reply = await axios.patch(url, payload);
    response.data = reply.data.data;
  } catch (err) {
    console.dir(err);
    response.isError = true;
    response.data = err.message;
    if (typeof err.response.data.errors !== "undefined") {
      if (err.response.data.errors.length > 0) {
        response.data = err.response.data.errors[0].message;
      }
    }
  }
  return response;
};
