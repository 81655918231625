export const parseOut = (rawQuery) => {
  if (rawQuery && rawQuery !== "") {
    let queryStr = "";
    if (rawQuery[0] === "?") {
      queryStr = rawQuery.slice(1);
    }
    const queries = queryStr.split("&");
    const queryObject = queries.reduce((accumulator, currentQuery) => {
      const splitted = currentQuery.split("=");
      if (splitted && splitted.length === 2) {
        const result = { ...accumulator };
        result[splitted[0]] = splitted[1];
        return result;
      }
      return accumulator;
    }, {});

    return queryObject;
  }
  return {};
};
