/* eslint-disable */
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react components for routing our app without refresh
import { NavLink } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
// import Apps from "@material-ui/icons/Apps";
// import ShoppingCart from "@material-ui/icons/ShoppingCart";
// import ViewDay from "@material-ui/icons/ViewDay";
// import Dns from "@material-ui/icons/Dns";
// import Build from "@material-ui/icons/Build";
// import ListIcon from "@material-ui/icons/List";
// import People from "@material-ui/icons/People";
// import Assignment from "@material-ui/icons/Assignment";
// import MonetizationOn from "@material-ui/icons/MonetizationOn";
// import Chat from "@material-ui/icons/Chat";
// import Call from "@material-ui/icons/Call";
// import ViewCarousel from "@material-ui/icons/ViewCarousel";
// import AccountBalance from "@material-ui/icons/AccountBalance";
// import ArtTrack from "@material-ui/icons/ArtTrack";
// import ViewQuilt from "@material-ui/icons/ViewQuilt";
// import LocationOn from "@material-ui/icons/LocationOn";
// import Fingerprint from "@material-ui/icons/Fingerprint";
// import AttachMoney from "@material-ui/icons/AttachMoney";
// import Store from "@material-ui/icons/Store";
// import AccountCircle from "@material-ui/icons/AccountCircle";
// import PersonAdd from "@material-ui/icons/PersonAdd";
// import Layers from "@material-ui/icons/Layers";
// import ShoppingBasket from "@material-ui/icons/ShoppingBasket";
// import LineStyle from "@material-ui/icons/LineStyle";
// import Error from "@material-ui/icons/Error";

// // core components
// import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
// import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-pro-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  const scrollGo = (element, to, duration) => {
    var start = element.scrollTop,
      change = to - start,
      currentTime = 0,
      increment = 20;

    var animateScroll = function() {
      currentTime += increment;
      var val = easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  };
  var onClickSections = {};

  const { dropdownHoverColor } = props;
  const classes = useStyles();
  return (
    <List className={classes.list + " " + classes.mlAuto}>
      <ListItem className={classes.listItem}>
      <Tooltip
          id="home-tt"
          title="No Place Like Home"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <NavLink to="/" className={classes.linkAlt}>
            Home
          </NavLink>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
      <Tooltip
          id="redeem-tt"
          title="Redeem Your Voucher Today"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <NavLink to="/redeem" className={classes.linkAlt}>
            Redeem
          </NavLink>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
      <Tooltip
          id="terms-tt"
          title="Voucher Terms and Conditions"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <NavLink to="/terms" className={classes.linkAlt}>
            Terms
          </NavLink>
        </Tooltip>
      </ListItem>
    </List>
  );
}

HeaderLinks.defaultProps = {
  hoverColor: "primary"
};

HeaderLinks.propTypes = {
  dropdownHoverColor: PropTypes.oneOf([
    "dark",
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose"
  ])
};
