import { container, title } from "assets/jss/material-kit-pro-react.js";

const termsPageStyle = {
  pcontent: {
    color: "#000 !important",
    marginTop: "20px",
    fontSize: "16px !important",
  },
  olstuff: {
    listStyleType: "none",
  },
  filter: {
    "&:before": {
      background: "rgba(255, 255, 255, 0.85)",
    },
    "&:after,&:before": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: "''",
    },
  },
  container: {
    zIndex: "12",
    color: "#FFFFFF",
    minHeight: "100vh !important",
    backgroundColor: "#FFFFFF !important",
    ...container,
  },
  title: {
    ...title,
    display: "block",
    position: "relative",
    marginTop: "90px",
    paddingTop: "20px",
    minHeight: "32px",
    color: "#000000",
    textDecoration: "none",
  },
  subtitle: {
    fontSize: "1.313rem",
    maxWidth: "500px",
    margin: "10px auto 0",
  },
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3",
  },
  mainRaised: {
    margin: "-60px 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
  },
  mainAlt: {
    background: "rgb(40,50,74)",
    position: "relative",
    zIndex: "3",
  },
};

export default termsPageStyle;
