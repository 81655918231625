/*!

=========================================================
* Material Kit PRO React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router";

import "assets/scss/material-kit-pro-react.scss?v=1.9.0";

// pages for this product
import LandingPage from "views/LandingPage/LandingPage.js";
import RedeemPage from "views/RedeemPage/RedeemPage.js";
import TermsPage from "views/TermsPage/TermsPage.js";
import NotFoundPage from "views/NotFoundPage/NotFoundPage";

var hist = createBrowserHistory();

// ReactDOM.render(
//   <Router history={hist}>
//     <Switch>
//       <Route path="/about-us" component={AboutUsPage} />
//       <Route path="/blog-post" component={BlogPostPage} />
//       <Route path="/blog-posts" component={BlogPostsPage} />
//       <Route path="/components" component={ComponentsPage} />
//       <Route path="/contact-us" component={ContactUsPage} />
//       <Route path="/ecommerce-page" component={EcommercePage} />
//       <Route path="/landing-page" component={LandingPage} />
//       <Route path="/login-page" component={LoginPage} />
//       <Route path="/pricing" component={PricingPage} />
//       <Route path="/profile-page" component={ProfilePage} />
//       <Route path="/product-page" component={ProductPage} />
//       <Route path="/sections" component={SectionsPage} />
//       <Route path="/shopping-cart-page" component={ShoppingCartPage} />
//       <Route path="/signup-page" component={SignupPage} />
//       <Route path="/error-page" component={ErrorPage} />
//       <Route path="/" component={PresentationPage} />
//     </Switch>
//   </Router>,
//   document.getElementById("root")
// );

const routing = (
  <Router history={hist}>
    <div>
      <Switch>
        <Route exact path="/" component={LandingPage} />
        <Route path="/redeem" component={RedeemPage} />
        <Route path="/terms" component={TermsPage} />
        <Route component={NotFoundPage} />
      </Switch>
    </div>
  </Router>
);

ReactDOM.render(routing, document.getElementById("root"));
