import React from "react";
import Produce from "immer";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// utils
import {
  get as Apiget,
  redeem as Apiredeem,
  voucherTypes,
} from "../../api/merge";
import { parseOut } from "../../utils/utils";
import { useLocation } from "react-router-dom";

// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Notifications from "./Sections/Notifications";

// forms
import VoucherForm from "./Sections/VoucherForm";
import RedeemForm from "./Sections/RedeemForm";
import ConfirmationForm from "./Sections/ConfirmationForm";

// styles and assets
import styles from "../../assets/jss/material-kit-pro-react/components/redeemForm";
import image from "assets/img/dumpster-roof-01.png";
import ScheduleForm from "../../components/ScheduleForm";
const Styles = makeStyles(styles);

const actions = {
  RESET: "RESET",
  VOUCHERGET: "VOUCHERGET",
  VOUCHERGETERR: "VOUCHERGETERR",
  VOUCHERGETDONE: "VOUCHERGETDONE",
  NOTIFY: "NOTIFY",
  REDEEM: "REDEEM",
  REDEEMDONE: "REDEEMDONE",
  REDEEMERROR: "REDEEMERROR",
  REDEEMRESET: "REDEEMRESET",
};

const UseQuery = (location) => {
  const response = {
    hasId: false,
    id: null,
    e: 3,
  };
  const query = parseOut(location.search);
  if (typeof query.c !== "undefined") {
    response.hasId = true;
    response.id = query.c;
  }

  if (typeof query.e !== "undefined") {
    response.e = query.e;
  }

  return response;
};

/**
 * STATE
 */

/**
 * Initial state
 */
const initialState = {
  data: {
    voucher: null,
    service: {},
    schemata: null,
  },
  cid: null,
  e: 3,
  status: {
    notify: false,
    inError: false,
    inWarning: false,
    redeemed: false,
    inInfo: false,
    type: null,
    msgId: null,
    msg: null,
  },
};

/**
 * Inits the useReducer in main component
 * @param {object} location
 */
const init = (location) => (initialState) => {
  var query = UseQuery(location);
  if (query.hasId) {
    console.log(`query.id: ${query.id}`);
    return { ...initialState, cid: query.id, e: query.e };
  } else {
    return { ...initialState, e: query.e };
  }
};

/**
 * Returns sections of the state to their initial values
 * @param {string} section the section of the state to return from it's initial state
 */
const resetState = (section = null) => {
  switch (section) {
    case null:
      return initialState;
    case "status":
      return {
        notify: false,
        inError: false,
        inWarning: false,
        redeemed: false,
        inInfo: false,
        type: null,
        msgId: null,
        msg: null,
      };
    case "data":
      return {
        voucher: null,
        service: {},
        schemata: null,
      };
    default:
      return initialState;
  }
};

/**
 * Gets the voucher from Merge
 * @param {string} id the voucher id to return
 *  @param {number} e the environment to use for the call
 */
const getVoucher = async (id, e) => {
  const reply = await Apiget(id, e);
  return reply;
};

/**
 * Redeem the voucher
 * @param {string} id the voucher id to redeem
 * @param {object} data the captured data from the service scheduling form
 *  @param {number} e the environment to use for the call
 */
const redeemVoucher = async (id, data, e) => {
  const reply = await Apiredeem(id, data, e);
  return reply;
};

/**
 * Notification setup
 * @param {string} action
 * @param {object} info
 */
const notify = (action, info = null) => {
  const response = resetState("status");
  switch (action) {
    case actions.VOUCHERGET:
      response.type = "warning";
      response.msg = `Retrieving Voucher: ${info}`;
      break;
    case actions.VOUCHERGETDONE:
      break;
    case actions.VOUCHERGETERR:
      response.inError = true;
      response.type = "error";
      response.msg = info;
      response.notify = true;
      break;
    case actions.REDEEMERROR:
      response.inError = true;
      response.type = "error";
      response.msg = info;
      response.notify = true;
      break;
    case actions.REDEEMDONE:
      response.type = "success";
      response.msg = "Your Request has been sent and is being reviewed.";
      response.notify = true;
      response.redeemed = true;
      break;
    case actions.NOTIFY:
      response.inError = info.type === "error" ? true : false;
      response.type = info.type;
      response.msg = info.msg;
      response.notify = true;
      break;
    case actions.RESET:
      break;
    default:
      break;
  }
  return response;
};

/**
 * Makeshift reducer
 * @param {object} state
 * @param {object} action
 */
const reducer = (state, action) => {
  return Produce(state, (draft) => {
    switch (action.type) {
      case actions.VOUCHERGET:
        draft.status = notify(actions.VOUCHERGET, action.id);
        draft.cid = action.id;
        break;
      case actions.VOUCHERGETDONE:
        draft.status = notify(actions.VOUCHERGETDONE);
        draft.data.voucher = action.payload;
        draft.data.schemata = action.payload.type;
        break;
      case actions.VOUCHERGETERR:
        draft.status = notify(action.type, action.error);
        draft.data = resetState("data");
        draft.cid = null;
        break;
      case actions.NOTIFY:
        draft.status = notify(actions.NOTIFY, action.payload);
        break;
      case actions.REDEEM:
        draft.status = notify(action.type);
        draft.data.service = action.payload;
        break;
      case actions.REDEEMDONE:
        draft.status = notify(actions.REDEEMDONE, action.payload);
        break;
      case actions.REDEEMERROR:
        draft.status = notify(action.type, action.error);
        draft.data.service = {};
        break;
      case actions.REDEEMRESET:
        draft.status = notify(action.type);
        draft.data.service = {};
        break;
      case actions.RESET:
        var reset = resetState();
        draft.data = reset.data;
        draft.status = reset.status;
        draft.cid = reset.cid;
        draft.e = action.e;
        break;
      default:
        break;
    }
  });
};

/**
 * MAIN COMPONENT
 * @param {object} props
 */
const RedeemPage = (props) => {
  const classes = Styles();
  const loc = useLocation();
  const [state, dispatch] = React.useReducer(reducer, initialState, init(loc));
  const { ...rest } = props;

  /**
   * HANDLERS
   */

  // VoucherForm submit event handler
  const handleVoucherFormSubmit = (event) => {
    event.preventDefault();
    const id = event.currentTarget.form[0].value;
    dispatch({ type: actions.VOUCHERGET, id });
  };

  // Service Form Submit Handler
  const handleScheduleFormSubmit = (data) => {
    dispatch({
      type: actions.REDEEM,
      payload: { ...data, scheduledDate: new Date(data.scheduledDate) },
    });
  };

  // Service Form Cancel Handler
  const handleScheduleServiceCancel = () => {
    dispatch({ type: actions.RESET, e: state.e });
  };

  // Service Form Clear Handler
  const handleScheduleServiceClear = () => {
    dispatch({ type: actions.REDEEMRESET });
  };

  /**
   * EFFECTS
   */

  // When a cid and/or environment changes
  React.useEffect(() => {
    if (state.cid !== null) {
      const handleGetVoucher = async (id) => {
        const response = await getVoucher(id, state.e);
        if (response.isError) {
          dispatch({
            type: actions.VOUCHERGETERR,
            error: response.data,
          });
        } else {
          dispatch({ type: actions.VOUCHERGETDONE, payload: response.data });
        }
      };
      handleGetVoucher(state.cid);
    }
  }, [state.cid, state.e]);

  // When the service form data changes
  React.useEffect(() => {
    if (state.cid !== null && Object.keys(state.data.service).length > 0) {
      const handleRedeemVoucher = async (id, data) => {
        const response = await redeemVoucher(id, data, state.e);
        // const response = { isError: false };
        if (response.isError) {
          dispatch({
            type: actions.REDEEMERROR,
            error: response.data,
          });
        } else {
          dispatch({
            type: actions.REDEEMDONE,
            payload: { ...data },
          });
        }
      };
      handleRedeemVoucher(state.cid, state.data.service);
    }
  }, [state.data.service, state.cid, state.e]);

  // render it
  return (
    <div>
      <Header
        fixed
        color="white"
        brand="TelaVoucher"
        links={<HeaderLinks dropdownHoverColor="info" />}
        {...rest}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            {state.status.notify === true ? (
              <GridItem xs={12} sm={12} md={8}>
                <Notifications
                  type={state.status.type !== null ? state.status.type : ""}
                  which={state.status.msgId !== null ? state.status.msgId : ""}
                  adhoc={state.status.msg !== null ? state.status.msg : ""}
                  display={state.status.notify}
                />
              </GridItem>
            ) : (
              <span></span>
            )}
          </GridContainer>
          {state.data.voucher !== null && state.status.redeemed === false ? (
            <GridContainer justify="center">
              <ScheduleForm
                schemata={state.data.schemata}
                onSubmit={handleScheduleFormSubmit}
                onClickCancelHandler={handleScheduleServiceCancel}
                onClickClearHandler={handleScheduleServiceClear}
                voucher={state.data.voucher}
                voucherTypes={voucherTypes}
              />
              <RedeemForm
                redemptionCode={state.cid}
                voucher={state.data.voucher}
              />
            </GridContainer>
          ) : state.status.redeemed === true ? (
            <GridContainer justify="center">
              <ConfirmationForm
                requestInfo={state.data.service}
                onClickCancelHandler={handleScheduleServiceCancel}
                voucherTypes={voucherTypes}
                schemata={state.data.schemata}
              />
              <RedeemForm
                redemptionCode={state.cid}
                voucher={state.data.voucher}
              />
            </GridContainer>
          ) : state.cid === null ? (
            <GridContainer justify="center">
              <VoucherForm
                onClickSubmitHandler={handleVoucherFormSubmit}
                error={state.status.inError}
              />
            </GridContainer>
          ) : (
            <span></span>
          )}
        </div>
        <Footer
          content={
            <div className={classes.center}>
              &copy; {1900 + new Date().getYear()} TelaVouchers powered by
              <a
                href="https://www.telaclaims.com"
                className="aClasses"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                Telaclaims
              </a>
            </div>
          }
        />
      </div>
    </div>
  );
};
export default RedeemPage;
