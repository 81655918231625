import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import TermsArea from "components/TermsArea/TermsAreas.js";

import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-pro-react/views/landingPageSections/termsStyle.js";

const useStyles = makeStyles(styles);

export default function TermsSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Terms and Conditions</h2>
        </GridItem>
      </GridContainer>
      <div>
        <TermsArea title="Not much fine print; but our laywers insist we have to have some. Click below. " />
        <br />
        <Button
          color="success"
          size="lg"
          href="/terms"
          target="_parent"
          rel="noopener noreferrer"
        >
          Click Here
        </Button>
      </div>
    </div>
  );
}
