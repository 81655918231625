import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import ServiceArea from "components/ServiceArea/ServiceAreas.js";

import styles from "assets/jss/material-kit-pro-react/views/landingPageSections/serviceareaStyle.js";

const useStyles = makeStyles(styles);

export default function ServiceAreaSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Our Services</h2>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <ServiceArea title="50" description="STATES" />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <ServiceArea title="24/7" description="SERVICE" />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <ServiceArea title="$0" description="YOUR COSTS" />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
