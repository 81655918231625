import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { createIntl, createIntlCache } from "react-intl";
import styles from "assets/jss/material-kit-pro-react/components/redeemForm";

const useStyles = makeStyles(styles);

// prep react-intl
const cache = createIntlCache();
const intl = createIntl(
  {
    locale: "en-US",
    message: {},
  },
  cache
);

const hideSectionSelections = (line) => {
  // condition 1 - roof vocuher - name and value for roof status
  if (line.name === "roof_b_status" && line.value === "No Other Roof Type") {
    return true;
  }
  return false;
};
const hideSection = (oline) => {
  const filter = oline.filter((line) => {
    return hideSectionSelections(line);
  });
  return filter.map((line) => {
    return line.name;
  });
};
const isRenderable = (line, hide) => {
  if (typeof line.group === "undefined" || line.group.length === 0) {
    return true;
  } else {
    const hidden = hide.filter((value) => {
      return line.group.indexOf(value) !== -1;
    });
    return hidden.length === 0;
  }
};
const renderItem = (line, index, hide) => {
  if (isRenderable(line, hide)) {
    const value =
      line.type === "MONEY_INPUT"
        ? intl.formatNumber(line.value, {
            style: "currency",
            currency: "USD",
          })
        : line.value;
    if (line.type === "TITLE_CAPTION") {
      return (
        <h6 key={index + 1}>
          <strong>{line.caption}</strong>
        </h6>
      );
    } else {
      return (
        <li key={index + 1}>
          {line.caption} {":"} {value}
        </li>
      );
    }
  }
};
export default function RedeemForm(props) {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  setTimeout(function () {
    setCardAnimation("");
  }, 700);
  const classes = useStyles();
  const voucher = {
    set: { info: false, order: false },
    claimNumber: "",
    insuredName: "",
    lossLocation: "",
    redemptionCode: props.redemptionCode || "",
    order: "",
  };
  if (typeof props.voucher.claim_info !== "undefined") {
    voucher.set.info = true;
    voucher.claimNumber = props.voucher.claim_info.claim_number || "NA";
    voucher.insuredName = props.voucher.claim_info.insured_name || "NA";
    voucher.lossLocation =
      props.voucher.claim_info.loss_location.unformatted_address || "NA";
    let orderLines = [];
    if (typeof props.voucher.order_info !== "undefined") {
      const olines = props.voucher.order_info.slice().sort((a, b) => {
        return a.seq - b.seq;
      });
      const hide = hideSection(olines);
      orderLines = olines.map((line, index) => {
        return renderItem(line, index, hide);
      });
    }
    voucher.order = orderLines.length > 0 ? orderLines : "";
    voucher.set.order = voucher.order !== "" ? true : false;
  }

  return (
    <div>
      <GridItem xs={12} sm={12} md={12}>
        <Card className={classes[cardAnimaton]}>
          <form className={classes.form}>
            <CardHeader color="info" className={classes.cardHeader}>
              <h3 align={"left"} className={classes.title}>
                Your Voucher
              </h3>
            </CardHeader>
            <CardBody>
              <p className={classes.titleSpacer}></p>
              <p>
                <strong>Redemption Code: </strong>
                {voucher.redemptionCode}
              </p>
              <p>
                <strong>Claim Number: </strong>
                {voucher.claimNumber}
              </p>
              <p>
                <strong>Insured Name: </strong>
                {voucher.insuredName}
              </p>
              <p>
                <strong>Loss Location: </strong>
                {voucher.lossLocation}
              </p>
              <div>
                <strong>Order Information: </strong>
                {voucher.order}
              </div>
            </CardBody>
          </form>
        </Card>
      </GridItem>
    </div>
  );
}
RedeemForm.propTypes = {
  redemptionCode: PropTypes.string,
  voucher: PropTypes.object,
};
