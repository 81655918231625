import React from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import NumberFormat from "react-number-format";
import FormHelperText from "@material-ui/core/FormHelperText";
// import { FormattedMessage } from "react-intl";

import Styled from "./styles";

/**
 * FormattedNumberField Component Description
 */
class FormattedNumberField extends React.PureComponent {
  // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {};
  }

  adjustAllowingEmptyFormating = focused => {
    this.setState({ focused });
  };

  render() {
    const {
      customInput,
      format,
      label,
      messages,
      showErrors,
      ...otherProps
    } = this.props;
    const { focused } = this.state;
    const propsToSpread = {
      ...otherProps,
      allowEmptyFormatting: otherProps.allowEmptyFormatting || focused
    };
    return (
      <Styled.Container>
        <NumberFormat
          label={label}
          customInput={customInput}
          format={format}
          error={showErrors}
          onFocus={() => this.adjustAllowingEmptyFormating(true)}
          onBlur={() => this.adjustAllowingEmptyFormating(false)}
          {...propsToSpread}
        />
        <Styled.ErrorContainer>
          {showErrors && messages && (
            <FormHelperText>{messages}</FormHelperText>
          )}
        </Styled.ErrorContainer>
      </Styled.Container>
    );
  }
}

FormattedNumberField.propTypes = {
  /** Input supplied to NumberFormat component */
  customInput: PropTypes.elementType,
  /**  Format to be applied to NumberFormat component */
  format: PropTypes.string,
  /** Label for text field */
  label: PropTypes.string,
  /** Applies for with for Material Ui Text Field */
  fullWidth: PropTypes.bool,
  /** Show error */
  showErrors: PropTypes.bool,
  /** Message to display */
  messages: PropTypes.string
};

FormattedNumberField.defaultProps = {
  customInput: TextField
};

export default FormattedNumberField;
