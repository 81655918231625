import {
  primaryColor,
  warningColor,
  dangerColor,
  successColor,
  infoColor,
  roseColor,
  grayColor,
  // title
} from "assets/jss/material-kit-pro-react.js";

const termsStyle = {
  termsArea: {
    margin: "0 auto",
    padding: "0px",
  },
  primary: {
    color: primaryColor,
  },
  warning: {
    color: warningColor,
  },
  danger: {
    color: dangerColor,
  },
  success: {
    color: successColor,
  },
  info: {
    color: infoColor,
  },
  rose: {
    color: roseColor,
  },
  gray: {
    color: grayColor,
  },
  title: {
    color: "#ffffff",
    overflow: "hidden",
    marginTop: "0px",
    fontSize: "22px",
  },
};

export default termsStyle;
