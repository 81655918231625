import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
// import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-kit-pro-react/components/serviceareaStyle.js";

const useStyles = makeStyles(styles);

export default function ServiceArea(props) {
  const classes = useStyles();
  const { title, description } = props;
  return (
    <div className={classes.infoArea}>
      <div className={classes.descriptionWrapper}>
        <h1 className={classes.title}>{title}</h1>
        <h5 className={classes.description}>{description}</h5>
      </div>
    </div>
  );
}

ServiceArea.defaultProps = {
  iconColor: "gray",
};

ServiceArea.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  iconColor: PropTypes.oneOf([
    "primary",
    "warning",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  vertical: PropTypes.bool,
};
