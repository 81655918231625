import React from "react";
import { withJsonFormsControlProps } from "@jsonforms/react";
import DateFnsUtils from "@date-io/date-fns";
import merge from "lodash/merge";
import "date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";
import { computeLabel } from "@jsonforms/core";

function DatePicker(props) {
  const {
    description,
    data,
    handleChange,
    path,
    required,
    config,
    uischema,
    label,
    errors,
  } = props;
  const appliedUiSchemaOptions = merge({}, config, uischema.options);
  const isValid = errors.length === 0;
  const todayPlusOne = moment(new Date()).add(1, "d").format("L");
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        // fullWidth
        // error={!isValid}
        // margin="normal"
        value={data || null}
        id="date-picker-dialog"
        label={computeLabel(
          label,
          required,
          appliedUiSchemaOptions.hideRequiredAsterisk
        )}
        format="MM/dd/yyyy"
        minDate={todayPlusOne}
        helperText={!isValid ? errors : description ? description : " "}
        onChange={(ev) => {
          handleChange(path, ev ? moment(ev).format("L") : "");
        }}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
      />
    </MuiPickersUtilsProvider>
  );
}

export default withJsonFormsControlProps(DatePicker);
