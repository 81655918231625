import styled from 'styled-components'

export default {
  Container: styled.div`
    position: relative;
    width: 100%;
  `,
  ErrorContainer: styled.div`
    position: absolute;
    color: red;
    p {
      color: red;
    }
  `,

}
