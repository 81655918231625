import React from "react";

import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
// import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import CreateIcon from "@material-ui/icons/Create";

// core components
import { Typography } from "@material-ui/core";

// import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import styles from "../../../assets/jss/material-kit-pro-react/views/loginPageStyle.js";

const useStyles = makeStyles(styles);

export default function VoucherForm(props) {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  setTimeout(function () {
    setCardAnimation("");
  }, 400);
  const classes = useStyles();
  return (
    <div>
      <GridItem xs={12} sm={12} md={12}>
        <h1 align={"center"} className={classes.title}>
          Enter A Voucher Id
        </h1>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <Card className={classes[cardAnimaton]}>
          <form onSubmit={props.onClickSubmitHandler} className={classes.form}>
            <CardBody>
              <CustomInput
                labelText="Enter You Voucher Number"
                id="voucherId"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  type: "voucherId",
                  error: props.error,
                  endAdornment: (
                    <InputAdornment position="end">
                      <CreateIcon className={classes.inputIconsColor} />
                    </InputAdornment>
                  ),
                }}
              />
            </CardBody>
            <CardFooter className={classes.cardFooter}>
              <Button
                simple
                color="primary"
                size="lg"
                onClick={props.onClickSubmitHandler}
              >
                <Typography variant="button" color="primary">
                  Get Voucher Information
                </Typography>
              </Button>
            </CardFooter>
          </form>
        </Card>
      </GridItem>
    </div>
  );
}

VoucherForm.propTypes = {
  onClickSubmitHandler: PropTypes.func,
  error: PropTypes.bool,
};
