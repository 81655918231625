import { title } from "assets/jss/material-kit-pro-react.js";

const termsStyle = {
  section: {
    padding: "40px 0 70px 0",
    textAlign: "center",
    backgroundColor: "rgb(40, 50, 74)",
  },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
    color: "#ffffff",
  },
  description: {
    color: "#999",
  },
};

export default termsStyle;
